import {addErrorClass, addLoadingClass, addSuccessClass} from "./utilities/updateClasses";

function fallbackCopyTextToClipboard(text, success = () => {
}, error = () => {
}) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        if (successful) {
            success();
        } else {
            console.error('Fallback: Oops, unable to copy');
            error();
        }
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        error();
    }

    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text, success = () => {
}, error = () => {
}) {
    success();
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text, success, error);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        success();
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
        error();
    });
}

export function triggerCopyLink(query = document.querySelectorAll('.js-copyLink')) {
    let timeoutCopy = false;
    for (let link of query) {
        link.addEventListener("click", function (e) {
            addLoadingClass(link);
            clearTimeout(timeoutCopy);
            timeoutCopy = setTimeout(function () {
                let dataLink = link.dataset.link;
                if (dataLink) {
                    copyTextToClipboard(dataLink, function () {
                        addSuccessClass(link);
                    }, function () {
                        addErrorClass(link);
                    });
                }
            }, 500);
            e.preventDefault();
        });
    }
}