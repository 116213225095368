/*jshint esversion: 6 */
import {
    addActionClass,
    addErrorClass,
    addLoadingClass,
    addSuccessClass,
    removeLoadingClass
} from "./utilities/updateClasses";
import {itemcardSubscriptions} from "./subscriptions";
import {hideIconbarHover, initIconbarHoverClose, showIconbarHover} from "./iconbarHover";
import {initCustomSelects} from "./customSelect";
import {initToggleSlide} from "./toggle";
import {XhrService} from "../ts/xhr.service";
import {appendScriptToDOM} from "./utilities/functions";
import {initFormSubmitChanges} from "./actionClick";
import {showModal} from "./modal";
import {closeModal} from "./modal";
import {initInputBody} from "./inputfile";
import {focusInputGroups} from "./checkInputGroups";
import {getNewFlashmessage} from "./flashmessages";

function updateBasketCount(doc) {
    for (let basketCount of document.querySelectorAll('.js-basketCount')) {
        let firstNewBasketCount = doc.querySelector('.js-basketCount');
        if (firstNewBasketCount) {
            let count = parseInt(firstNewBasketCount.innerText);
            if (isNaN(count)) {
                count = 0;
            }
            if (count === 0) {
                basketCount.style.display = "none";
            } else {
                addActionClass(basketCount, function () {
                    basketCount.style.display = "";
                });
            }
        }
    }
}

function updateItem(target, doc) {
    let dataItemNo = target.dataset.itemNo;
    if (dataItemNo) {
        let addOn = target.closest(".js-updateItem");
        if (addOn) {
            let newTarget = doc.querySelector('.js-addListItemToBasket[data-item-no="' + dataItemNo + '"]');
            if (newTarget) {
                let newaddOn = newTarget.closest('.js-updateItem');
                if (newaddOn) {
                    addOn.innerHTML = newaddOn.innerHTML;
                } else {
                    console.error("cannot update orderbutton");
                }
            }
        }
    }
}

function updateOrderButtonItemcard(doc) {
    let itemcardBuybox = document.querySelector(".itemcardBuybox");
    let newItemcardBuybox = doc.querySelector('.itemcardBuybox');

    if (itemcardBuybox) {
        itemcardBuybox.innerHTML = newItemcardBuybox.innerHTML;

        itemcardSubscriptions(itemcardBuybox);
        initCustomSelects(itemcardBuybox.querySelectorAll(".js-customSelect"));
    }
}

function updateHeaderBasket(doc) {
    let delayBasketHover = 6000;
    if(GLOBALS){
        if(GLOBALS.delayHideBasketHover){
            delayBasketHover = GLOBALS.delayHideBasketHover;
        }
    }
    for (let headerBasket of document.querySelectorAll('.js-headerBasket')) {
        let newHeaderBasket = doc.querySelector('.js-headerBasket');
        if (newHeaderBasket) {
            headerBasket.innerHTML = newHeaderBasket.innerHTML;
            headerBasket.classList.add("showIconbarHover");
            showIconbarHover(headerBasket);
            initIconbarHoverClose(headerBasket.querySelectorAll(".iconbarHover__close"));
            let basketCount = headerBasket.querySelector('.js-basketCount');

            if (basketCount) {
                addActionClass(basketCount);
            }

            setTimeout(function () {
                hideIconbarHover(headerBasket);
            }, delayBasketHover);
        }
    }
}

function eventListenerBasketViewQuantities(basketView, updateTimeout) {
    for (let quantity of basketView.querySelectorAll('.js-itemBasketList .quantity input[type="number"]')) {
        let form = quantity.closest(".js-itemBasketList");
        quantity.addEventListener("change", function () {
            clearTimeout(updateTimeout);
            updateTimeout = setTimeout(function () {
                sendBasketForm(form, basketView);
            }, 1000);
        });
        quantity.addEventListener("keyup", function (e) {
            if(e.key === "Enter"){
                sendBasketForm(form, basketView);
            }
        });
    }
}

function eventListenerBasketViewQuantitySpinners(basketView, updateTimeout) {
    for (let spinner of basketView.querySelectorAll('.js-itemBasketList .quantity .js-spinnerUp,.js-itemBasketList .quantity .js-spinnerDown')) {
        let form = spinner.closest(".js-itemBasketList");
        spinner.addEventListener("click", function () {
            clearTimeout(updateTimeout);
            updateTimeout = setTimeout(function () {
                sendBasketForm(form, basketView);
            }, 1000);
        });
    }
}

function updateAllBasketViews(basketView, newBasketView) {
    if (basketView && newBasketView) {
        let oldList = basketView.querySelector('.js-itemBasketList');
        let oldOrderBox = basketView.querySelector('.js-basketOrderBox');
        let oldSummary = basketView.querySelector('.js-basketSummary');

        let newList = newBasketView.querySelector('.js-itemBasketList');
        let newOrderBox = newBasketView.querySelector('.js-basketOrderBox');
        let newSummary = newBasketView.querySelector('.js-basketSummary');

        if (newList) {
            oldList.innerHTML = newList.innerHTML;
            removeLoadingClass(oldList);
            eventListenerBasketViewQuantities(basketView);
            eventListenerBasketViewQuantitySpinners(basketView);
        } else {
            basketView.innerHTML = newBasketView.innerHTML;
            return;
        }

        if (newOrderBox) {
            oldOrderBox.innerHTML = newOrderBox.innerHTML;
            removeLoadingClass(oldOrderBox);
            initToggleSlide(oldOrderBox.querySelectorAll(".js-toggleLinkSlide"));
            initFormSubmitChanges();
        }
        if (newSummary) {
            oldSummary.innerHTML = newSummary.innerHTML;
        }
    }
}

function sendBasketForm(form, basketView) {
    let formData = new FormData(form);
    addLoadingClass(basketView.querySelector('.js-itemBasketList'));
    addLoadingClass(basketView.querySelector('.js-basketOrderBox'));
    fetch(form.action, {
        method: "POST",
        body: formData,
    })
        .then((response) => {
            if (!response.ok) {
                throw "Could not save data";
            }
            return response.text();
        })
        .then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            updateAllBasketViews(basketView, doc.getElementById('userBasket'));
        })
        .catch((e) => {
            console.error('Error:', e);
        });
}

export function triggerBasketBtns() {
    document.addEventListener("click", function (e) {
        let target = e.target;

        if (target.closest(".js-addListItemToBasket")) {
            target = target.closest(".js-addListItemToBasket");
            let form = target.closest("form");
            let orderButtonWrapper = target.closest(".orderButtonWrapper");
            let quantityInput = orderButtonWrapper.querySelector('.js-quantity-value');

            if (form && quantityInput) {
                if (quantityInput.value > 0) {
                    addLoadingClass(target);
                    let formData = new FormData(form);
                    let action = form.dataset.action;
                    formData.set('action', action);
                    XhrService.generalAjaxCall('cart', formData, 'POST', 'json').then(response => {
                        if (!response.status) {
                            throw "Could not save data";
                        }
                        const headerBasket = document.querySelector('.js-headerBasket');
                        if (headerBasket && response.headerBasketContent) {
                            var doc = new DOMParser().parseFromString(response.headerBasketContent, "text/html");
                            updateHeaderBasket(doc);
                        }
                        if (response.tagManager) {
                            appendScriptToDOM(response.tagManager, true);
                        }

                        if (response.dynamicYield) {
                            appendScriptToDOM(response.dynamicYield, true);
                        }

                        if(response.emarsys_scripts){
                            appendScriptToDOM(doc.getElementById('emarsys_scripts').innerHTML, true);
                        }
                        if(response.showInsuranceError) {
                            getNewFlashmessage(response.showInsuranceError, 3);
                            addErrorClass(target);
                        }
                        if(response.showPriceError) {
                            getNewFlashmessage(response.showPriceError, 3);
                            addErrorClass(target);
                        }
                        form.reset();
                        addSuccessClass(target);
                        return response.status;
                    })
                        .catch((e) => {
                            addErrorClass(target);
                            console.error('Error:', e);
                            console.error('Action:', form.action);
                            console.error(formData);
                        });
                }

            }
            e.preventDefault();
        }

        if (target.closest(".js-addToBasketItemcard")) {
            target = target.closest(".js-addToBasketItemcard");
            let form = target.closest("form");
            let orderButtonWrapper = target.closest(".orderButtonWrapper");
            let quantityInput = orderButtonWrapper.querySelector('.js-quantity-value');
            let customizableInputValue = form.dataset.customized;
            let quantityValue = 0;

            if ((form && quantityInput) || (form && customizableInputValue === '1'))  {
                if (quantityInput) {
                    quantityValue = quantityInput.value;
                }
                if (quantityValue > 0 || customizableInputValue === '1') {
                    addLoadingClass(target);
                    let fn = 'cart';
                    let formData = new FormData(form);
                    let action = form.dataset.action;
                    formData.set('action', action);
                    if (customizableInputValue === '1') {
                        fn = 'cartCustomize';
                    }

                    XhrService.generalAjaxCall(fn, formData, 'POST', 'json').then(response => {
                        if (!response.status) {
                            throw "Could not save data";
                        }
                        addSuccessClass(target);
                        if (fn == 'cartCustomize') {
                            let existingModal = document.getElementById('modalUserQueue');
                            if (existingModal) {
                                existingModal.remove();
                            }

                            const parser = new DOMParser();
                            const customizeModalParser = parser.parseFromString(response.customizeModal, 'text/html');
                            const customizeModal = customizeModalParser.querySelector('.modal');

                            if(customizeModal){
                                document.body.appendChild(customizeModal);
                                initInputBody(customizeModal.querySelectorAll(".fileBody"));
                                focusInputGroups(customizeModal.querySelectorAll('.inputGroup input,.inputGroup select,.inputGroup textarea'));
                                showModal(customizeModal);
                            }
                        } else {
                            const headerBasket = document.querySelector('.js-headerBasket');
                            if (headerBasket && response.headerBasketContent && !response.showInsuranceError && !response.showPriceError) {
                                var doc = new DOMParser().parseFromString(response.headerBasketContent, "text/html");
                                updateHeaderBasket(doc);
                            }
                            if (response.tagManager) {
                                appendScriptToDOM(response.tagManager, true);
                            }

                            if (response.dynamicYield) {
                                appendScriptToDOM(response.dynamicYield, true);
                            }

                            if(response.emarsys_scripts && headerBasket && response.headerBasketContent){
                                appendScriptToDOM(doc.getElementById('emarsys_scripts').innerHTML, true);
                            }
                            form.reset();
                            let existingModal = document.getElementById('modalUserQueue');
                            if (existingModal) {
                                closeModal(existingModal);
                            }
                        }
                        if(response.showInsuranceError) {
                            getNewFlashmessage(response.showInsuranceError, 3);
                            addErrorClass(target);
                        }
                        if(response.showPriceError) {
                            getNewFlashmessage(response.showPriceError, 3);
                            addErrorClass(target);
                        }

                        return response.status;
                    })
                        .catch((e) => {
                            addErrorClass(target);
                            console.error('Error:', e);
                        });
                }
            }
            e.preventDefault();
        }

        if (target.closest(".js-removeFromBasketHover")) {
            target = target.closest(".js-removeFromBasketHover");

            addLoadingClass(target);
            let formData = new FormData();
            formData.set('action', target.dataset.action);
            formData.set('action_id', target.dataset.actionid);
            formData.set('var_code', target.dataset.var_code);
            formData.set('item_key', target.dataset.itemkey);


            XhrService.generalAjaxCall('cart', formData, 'POST', 'json').then(response => {
                addSuccessClass(target);
                if (!response.status) {
                    throw "Could not save data";
                }
                addSuccessClass(target);
                const headerBasket = document.querySelector('.js-headerBasket');
                if (headerBasket && response.headerBasketContent) {
                    var doc = new DOMParser().parseFromString(response.headerBasketContent, "text/html");
                    updateHeaderBasket(doc);
                }
                if (response.tagManager) {
                    appendScriptToDOM(response.tagManager, true);
                }

                if (response.dynamicYield) {
                    appendScriptToDOM(response.dynamicYield, true);
                }

                if(response.emarsys_scripts){
                    appendScriptToDOM(doc.getElementById('emarsys_scripts').innerHTML, true);
                }
                if(response.showInsuranceError) {
                    getNewFlashmessage(response.showInsuranceError, 3);
                    addErrorClass(target);
                }
                if(response.showPriceError) {
                    getNewFlashmessage(response.showPriceError, 3);
                    addErrorClass(target);
                }
                return response.status;
            })
                .catch((e) => {
                    addErrorClass(target);
                    console.error('Error:', e);
                });
            e.preventDefault();
        }

        if (target.closest(".js-saveBasketCustomization")) {
            target = target.closest(".js-saveBasketCustomization");
            addLoadingClass(target);

            let form = target.closest("form");
            let formData = new FormData(form);
            formData.set('action', form.dataset.action);

            XhrService.generalAjaxCall('saveCustomization', formData, 'POST', 'json').then(response => {
                if (!response.status) {
                    throw "Could not save data";
                }
                addSuccessClass(target);
                let customizationData = response.inputDataArray;
                Object.keys(customizationData).forEach(key => {
                    let cleanKey = key.replace("input", "");
                    let customizationText = document.getElementById(cleanKey);
                    customizationText.innerHTML = customizationData[key];
                });
                form.reset();
                let existingModal = document.getElementById('modalUserQueue');
                if (existingModal) {
                    closeModal(existingModal);
                }
            })
            .catch((e) => {
                addErrorClass(target);
                console.error('Error:', e);
            });

            e.preventDefault();
        }

        if (target.closest(".js-basketEditCustomization")) {
            target = target.closest(".js-basketEditCustomization");
            addLoadingClass(target);

            let formData = new FormData();
            formData.set('action', target.dataset.action);
            formData.set('action_id', target.dataset.actionid);
            formData.set('customization_hash', target.dataset.customizationhash);

            XhrService.generalAjaxCall('editCustomization', formData, 'GET', 'json').then(response => {
                if (!response.status) {
                    throw "Could not save data";
                }
                addSuccessClass(target);

                let itemBasketList = document.getElementById('form_user_basket');
                if (itemBasketList) {
                    let existingModal = document.getElementById('modalUserQueue');
                    if (existingModal) {
                        existingModal.remove();
                    }
                    let customizeModal = response.customizeModal;
                    let newElement = document.createElement('div');
                    newElement.innerHTML = customizeModal;
                    while (newElement.firstChild) {
                        itemBasketList.appendChild(newElement.firstChild);
                    }
                    newElement.remove();
                    initInputBody(document.getElementById("modalUserQueue").querySelectorAll(".fileBody"));
                    focusInputGroups(document.getElementById("modalUserQueue").querySelectorAll('.inputGroup input,.inputGroup select,.inputGroup textarea'));
                    showModal(document.getElementById("modalUserQueue"));
                }

                return response.status;
            })
                .catch((e) => {
                    addErrorClass(target);
                    console.error('Error:', e);
                });

            e.preventDefault();
        }
    });
}

export function triggerUpdateBasketView() {
    let updateTimeout = false;
    let basketView = document.getElementById("userBasket");
    if (basketView) {
        eventListenerBasketViewQuantities(basketView, updateTimeout);
        eventListenerBasketViewQuantitySpinners(basketView, updateTimeout);
    }
}