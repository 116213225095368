export function initCollectionFilter() {
    document.querySelectorAll('.collectionFilter .tag').forEach(function (filterButton) {
        filterButton.addEventListener("click", function (event) {
            document.querySelectorAll('.collectionFilter .tag').forEach(button => button.classList.remove('is-active'));
            this.classList.add('is-active');

            let category = this.getAttribute('data-filter');
            let form = this.closest("form");

            selectCollectionFilter(category, form);
        });
    });

    function selectCollectionFilter(category, form) {
        let elementContainer = document.querySelector('.filter-container');
        elementContainer.classList.add('is-loading');

        fetch(form.action, {
            method: "POST",
            body: new FormData(form),
        })
            .then(response => {
                if (!response.ok) throw new Error("Network response was not ok");
                return response.text();
            })
            .then(data => {
                let parser = new DOMParser();
                let doc = parser.parseFromString(data, "text/html");
                updateElementList(doc, category);
            })
            .catch(error => {
                console.error("Failed to load data:", error);
                elementContainer.classList.remove('is-loading');
            })
            .finally(() => {
                elementContainer.classList.remove('is-loading');
            });
    }

    function updateElementList(doc, category) {
        let elementContainer = document.querySelector('.filter-container');
        let newContent = doc.querySelectorAll('.filter-container .filtr-item');

        elementContainer.innerHTML = '';

        newContent.forEach(item => {
            // Extract categories from the item's data-category attribute
            let categories = item.dataset.category.split(' ');
            // Check if any category matches the selected filter or if the filter is '0' (all)
            if (categories.includes(`collection_group_${category}`) || category === '0') {
                elementContainer.appendChild(item.cloneNode(true));
            }
        });
    }
}
