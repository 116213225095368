import {shareLink} from "./sharing";
import {showModal} from "./modal";

export function shareItemcardModal(query = document.querySelectorAll('.js-openItemcardSharingModal')) {
    for (let link of query) {
        link.addEventListener("click", function () {
            if (navigator.canShare) {
                if (link.dataset.url && link.dataset.title) {
                    shareLink({
                        title: link.dataset.title,
                        url: link.dataset.url,
                    });
                }
            } else {
                showModal(document.getElementById("modalItemSharing"));
            }
        });
    }
}