export function subnaviScroll(query, activeClass = ".is-active") {
    for (let navigation of document.querySelectorAll(query)) {
        for (let activeEl of navigation.querySelectorAll(activeClass)) {
            let position = activeEl.getBoundingClientRect();
            if (position.left < 0 || position.right > window.innerWidth) {
                navigation.scrollLeft += position.left - 20;
            }
        }
    }
}


// Überprüfen und ausblenden des linken oder rechten Pfeils basierend auf der Scroll-Position
export function checkScrollNav() {
    let navList = document.getElementById('navList');
    let scrollNavStart = document.querySelector(".js-scrollNav-start");
    let scrollNavEnd = document.querySelector(".js-scrollNav-end");

    if (navList) {
        if (navList.scrollLeft <= 100) {
            scrollNavStart.style.display = 'none'; // Blende linken Pfeil aus, wenn ganz links
        } else {
            scrollNavStart.style.display = 'flex';
        }

        if (navList.scrollLeft + navList.clientWidth >= navList.scrollWidth) {
            scrollNavEnd.style.display = 'none'; // Blende rechten Pfeil aus, wenn ganz rechts
        } else {
            scrollNavEnd.style.display = 'flex';
        }
    }
}

// Scroll-Funktion für die Navigation
export function scrollNav(direction) {

    let navList = document.getElementById('navList');
    let scrollAmount = 200; // Scrollbetrag in Pixel

    if (navList) {
        if (direction === 'left') {
            navList.scrollBy({
                left: -scrollAmount,

            });
        } else if (direction === 'right') {
            navList.scrollBy({
                left: scrollAmount,

            });
        }

    }
}
