import {getOptionsFromJsonString} from "./utilities/options";

export function stickyBox(el, options = {}) {
    let defaultOptions = {
        selectorPinArea: ".js-stickyArea",
        minWidth: GLOBALS.gridBreakpoints.md,
        offsetTop: 0
    };

    let elWidth = 0;
    let isSticky = false;
    let scrollPositionOnSticky = 0;

    options = { ...defaultOptions, ...options };
    options = getOptionsFromJsonString(el.dataset.options, options);
    let pinArea = el.closest(options.selectorPinArea);

    function resetBox() {
        el.style.position = "";
        el.style.top = "";
        el.style.width = "";
        el.classList.remove("sticky");
        isSticky = false;
    }

    function setBoxSticky() {
        el.style.position = "fixed";
        el.style.top = options.offsetTop + "px";
        el.style.width = elWidth + "px";
        el.classList.add("sticky");
        isSticky = true;
    }

    function checkBox(first = false) {
        let elPosition = el.getBoundingClientRect();
        if (!isSticky) {
            elWidth = elPosition.width;
        }
        let pinAreaPosition = pinArea.getBoundingClientRect();

        var someDiv = document.querySelector(".js-stickyBox");
        var distanceToTop = someDiv.getBoundingClientRect().top;


        const stickyPosition = pinAreaPosition.top + options.offsetTop;

        if (elPosition.height < pinAreaPosition.height) {
            if (window.innerWidth >= options.minWidth) {
                if (!first) {
                    pinArea.style.minHeight = pinAreaPosition.height + "px";
                }

                if (!isSticky) {
                    scrollPositionOnSticky = window.scrollY;
                }
                //console.log(isSticky && window.scrollY < scrollPositionOnSticky);


                if (distanceToTop <= options.offsetTop) {
                    setBoxSticky();
                    let topPosition = options.offsetTop;
                    let spacerBetweenBoxAndAreaEnd =
                        pinAreaPosition.height + pinAreaPosition.top;
                    if (
                        spacerBetweenBoxAndAreaEnd - options.offsetTop <=
                        elPosition.height
                    ) {
                        topPosition =
                            pinAreaPosition.height +
                            pinAreaPosition.top -
                            elPosition.height;
                    }
                    el.style.top = topPosition + "px";
                } else {
                    resetBox()
                }

                if (isSticky && window.scrollY < scrollPositionOnSticky) {
                    resetBox();
                }
            }
        }
    }

    if (pinArea) {
        checkBox(true);
        window.addEventListener("scroll", checkBox);
        window.addEventListener("resize", checkBox);
        window.addEventListener("orientationchange", checkBox);
    } else {
        console.error("Box Area not found");
    }
}