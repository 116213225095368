import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';
import {getOptionsFromJsonString} from "./utilities/options";

export function initItemlistSlider(query = document.querySelectorAll('.itemboxList.swiper'),options = {}) {
    let defaultOptions = {
        breakpointItems: {
            xs: 2.2,
            sm: 3,
            md: 4,
            lg: 4,
            xlg: 5
        }
    }

    defaultOptions = {...defaultOptions, ...options};

    for (let itemlist of query) {

        let options = getOptionsFromJsonString(itemlist.getAttribute('data-options'), defaultOptions);
        let childrenCount = itemlist.querySelectorAll('.swiper-wrapper > *').length;
        let loop = false;

        if(itemlist.closest(".col-md-8")){
            options.breakpointItems.md = 3;
            options.breakpointItems.lg = 3;
            options.breakpointItems.xlg = 3;
        }

        let breakpoints = {
            xs: {
                items: options.breakpointItems.xs,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.xs),
            },
            sm: {
                items: options.breakpointItems.sm,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.sm),
            },
            md: {
                items: options.breakpointItems.md,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.md),
            },
            lg: {
                items: options.breakpointItems.lg,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.lg),
            },
            xlg: {
                items: options.breakpointItems.xlg,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.xlg),
            }
        }

        if (window.outerWidth > GLOBALS.gridBreakpoints.sm && childrenCount > breakpoints.sm.items) {
            loop = true;
        }

        if (window.outerWidth > GLOBALS.gridBreakpoints.md && childrenCount > breakpoints.md.items) {
            loop = true;
        }

        if (window.outerWidth > GLOBALS.gridBreakpoints.xlg && childrenCount > breakpoints.xlg.items) {
            loop = true;
        }

        Swiper.use([Autoplay, Navigation, Pagination]);
        new Swiper(itemlist, {
            loop: loop,
            speed: 300,
            spaceBetween: 0,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            autoHeight: false,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: itemlist.querySelector('.swiper-button-next'),
                prevEl: itemlist.querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: breakpoints.xs.items,
                    slidesPerGroup: Math.floor(breakpoints.xs.items)
                },
                768: {
                    slidesPerView: breakpoints.sm.items,
                    slidesPerGroup: Math.floor(breakpoints.sm.items),
                    autoplay: {
                        enabled: breakpoints.sm.autoplay
                    }
                },
                1024: {
                    slidesPerView: breakpoints.md.items,
                    slidesPerGroup: Math.floor(breakpoints.md.items),
                    autoplay: {
                        enabled: breakpoints.md.autoplay
                    }
                },
                1250: {
                    slidesPerView: breakpoints.lg.items,
                    slidesPerGroup: Math.floor(breakpoints.lg.items),
                    autoplay: {
                        enabled: breakpoints.lg.autoplay
                    }
                },
                1540: {
                    slidesPerView: breakpoints.xlg.items,
                    slidesPerGroup: Math.floor(breakpoints.xlg.items),
                    autoplay: {
                        enabled: breakpoints.xlg.autoplay
                    }
                }
            }
        });
    }
}