import flatpickr from "flatpickr";
import {German} from "flatpickr/dist/l10n/de";

export function initDatepicker(query = document.querySelectorAll('.js-datepicker'), onValueUpdate = function () {
}) {
    let locale = false;

    if(GLOBALS){
        if(GLOBALS.config.languageCode === "de"){
            locale = German;
        }
    }
    for(let picker of query){
        let min = "today";
        let minDate = picker.getAttribute("min");
        if (minDate !== "today") {
            min = minDate;
        }
        flatpickr(picker, {
            "locale": locale,
            altInput: true,
            altFormat: "d.m.Y",
            minDate: min,
            dateFormat: "d.m.Y",
            onValueUpdate: onValueUpdate,
            disableMobile: true
        });
    }

    // Initialize flatpickr explicitly for mobileFlatpickr elements
    let mobileFlatpickr = document.querySelectorAll('.flatpickr-mobile');
    mobileFlatpickr.forEach(function (el){
        flatpickr(el, {
            altInput: true,
            altFormat: "d.m.Y",
            dateFormat: "d.m.Y",
            onValueUpdate: onValueUpdate,
            disableMobile: true
        });
    });
}
