import {XhrService} from "../../../../default/global/ts/xhr.service";

export function initSendCollectionEntryMark(){
    let buttonSubmit = document.getElementById('mark_submit');

    if (buttonSubmit) {
        let isProcessing = false; // Flagge, um den Verarbeitungsstatus zu überwachen

        buttonSubmit.addEventListener('click', function () {
            if (isProcessing) {
                return; // Verhindern, dass der Klick während der Verarbeitung erneut ausgelöst wird
            }

            isProcessing = true; // Setzen Sie die Flagge auf true, um die Verarbeitung zu starten

            let markInput = document.getElementById('mark');
            let markValue = markInput.value;
            let collectionEntryId = this.attributes['collection_id'].value;

            sendMark(
                markValue,
                collectionEntryId,
                () => {
                    console.log('Before send like loading class adding');
                    const buttonElement = document.createElement('button');
                    buttonElement.className = 'actionIcon is-loading';
                    const containerElement = document.getElementById('mark_submit');
                    containerElement.appendChild(buttonElement);
                },
                () => {
                    console.log('Success');
                    const deleteLoadingButton = () => {
                        const loadingButton = document.querySelector('.is-loading');

                        if (loadingButton) {
                            loadingButton.remove();
                        }
                    };
                    deleteLoadingButton();
                    const buttonElement = document.createElement('button');
                    buttonElement.className = 'actionIcon is-success';
                    const containerElement = document.getElementById('mark_submit');
                    containerElement.appendChild(buttonElement);

                    setTimeout(() => {
                        buttonElement.style.display = 'none';
                        isProcessing = false; // Setzen Sie die Flagge zurück, nachdem die Verarbeitung abgeschlossen ist
                    }, 2000); // 2000 Millisekunden (2 Sekunden)
                },
                () => {
                    console.log('Fail!');
                    isProcessing = false; // Setzen Sie die Flagge zurück, wenn ein Fehler auftritt
                }
            );
        });
    }
}

export function sendMark(mark, collectionEntryId, beforeSend, successSend, errorSend) {
    const fd = new FormData();
    fd.append("collectionEntryId", collectionEntryId);
    fd.append("mark", mark);

    if (typeof beforeSend !== 'undefined') {
        beforeSend();
    }

    XhrService.generalAjaxCall('saveCollectionEntryMark', fd, 'POST', 'json').then(res => {
        if (typeof successSend !== 'undefined') {
            successSend(res);
        }
    }, err => {
        console.error(err);
        if (typeof errorSend !== 'undefined') {
            errorSend();
        }
    })
}