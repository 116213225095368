const togglePassword = document.querySelector('#togglePassword');
const password = document.querySelector('#input_password');

export function togglePasswordInput() {
    if(togglePassword) {
        togglePassword.addEventListener('click', function (e) {
            // toggle the type attribute
            const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            password.setAttribute('type', type);
            // toggle the eye slash icon
            this.classList.toggle('hide');
        });
    }
}

