export const moveElementsToSpecialNavigation = () => {
    const elementsToMove = ['Sale', 'Digitale Produkte', 'Topseller', 'Medizinprodukte', 'Neuheiten', 'Marken'];
    if(document.getElementById('specialNavigation')){
        const specialNavigation = document.getElementById('specialNavigation').querySelector('ul');

        elementsToMove.forEach(name => {
            const elements = document.querySelectorAll(`li[data-name="${name}"]`);
            elements.forEach(element => {
                // Überprüfen, ob das Element ein level_3 Element ist
                const hasLevel3Parent = element.closest('.level_3');
                if (!hasLevel3Parent) {
                    specialNavigation.appendChild(element.cloneNode(true));
                    element.remove();
                }
            });
        });
    }
};

export const limitSubMenuItems = () => {
    const subMenuItems = document.querySelectorAll('.shopnav .level_2');

    subMenuItems.forEach(subMenuItem => {
        const subMenu = subMenuItem.querySelector('ul');
        if (!subMenu) return; // Skip if no ul element found
        const menuItems = subMenu.querySelectorAll('.level_3');
        const itemCount = menuItems.length;

        let maxItems = 5; // Standardwert für maximale angezeigte Elemente

        // Bildschirmbreite überprüfen und maximale Elemente entsprechend festlegen
        if (window.innerWidth < 1651) {
            maxItems = 3;
        }

        if (window.innerWidth < 1251) {
            maxItems = 2;
        }

        if (itemCount > maxItems) {
            for (let i = maxItems; i < itemCount; i++) {
                menuItems[i].style.display = 'none';
            }

            const moreLink = document.createElement('li');
            moreLink.classList.add('level_3');
            const moreLinkAnchor = document.createElement('a');
            moreLinkAnchor.textContent = '...';
            const parentLink = subMenuItem.querySelector('a');

            // Setzen des "more" Links auf den Link des Eltern-Elements (.level_2)
            moreLinkAnchor.setAttribute('href', parentLink.getAttribute('href'));
            moreLink.appendChild(moreLinkAnchor);

            // Eventlistener für den "more" Link
            moreLinkAnchor.addEventListener('click', (event) => {
                event.preventDefault();
                window.location.href = parentLink.getAttribute('href');
            });

            subMenu.appendChild(moreLink);
        }
    });
};
